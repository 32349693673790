import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/pageHeader";
import BoardMember from "../components/BoardMember";
import CallToAction from "../components/application-cta";

const sortMembers = data => {
  let board = {
    president: {},
    vicePresident: {},
    generalSecretary: {},
    cashier: {},
    member: {},
  };

  data.nodes.forEach(member => {
    switch (member.position) {
      case "Πρόεδρος":
        board.president[member.name] = member;
        break;
      case "Αντιπρόεδρος":
        board.vicePresident[member.name] = member;
        break;
      case "Γενικός Γραμματέας":
        board.generalSecretary[member.name] = member;
        break;
      case "Ταμίας":
        board.cashier[member.name] = member;
        break;
      case "Μέλος":
        board.member[member.name] = member;
        break;
      default:
        break;
    }
  });

  let entries = Object.entries(board);
  board = [];

  for (const [position, details] of entries) {
    board.push({ position, details });
  }

  let cards = [];

  board.forEach(position => {
    let values = Object.values(position.details);
    values.forEach(member => {
      cards.push(<BoardMember key={member.id} member={member} />);
    });
  });

  return cards;
};

const BoardOfDirectors = ({ location, data }) => {
  let board20192021 = sortMembers(data.board20192021);
  let board20162019 = sortMembers(data.board20162019);

  return (
    <Layout pageTitle="board-of-directors">
      <SEO pageTitle="Διοικητικό Συμβούλιο" />
      <PageHeader
        pageTitle="ΔΙΟΙΚΗΤΙΚΟ ΣΥΜΒΟΥΛΙΟ"
        pageDescription="Το Διοικητικό Συμβούλιο της Κυπριακής Εταιρείας Αθηροσκλήρωσης που
        προέκυψε από τις αρχαιρεσίες της 26ης Σεπτεμβρίου 2019 συγκροτήθηκε σε
        σώμα με την ακόλουθη σύνθεση."
        page="Διοικητικό Συμβούλιο"
        location={location}
      />

      <div className="board-wrapper">
        <h2 className="board-title">ΔΙΟΙΚΗΤΙΚΟ ΣΥΜΒΟΥΛΙΟ 2019-2021</h2>
        {board20192021}
      </div>

      <div className="board-wrapper">
        <h2 className="board-title">ΔΙΟΙΚΗΤΙΚΟ ΣΥΜΒΟΥΛΙΟ 2016-2019</h2>
        {board20162019}
      </div>
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  query {
    board20192021: allContentfulBoardMembers20192021 {
      nodes {
        id
        name
        position
        attribute
      }
    }
    board20162019: allContentfulBoardMembers20162019 {
      nodes {
        id
        name
        position
        attribute
      }
    }
  }
`;

export default BoardOfDirectors;

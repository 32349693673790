import React from "react";

const BoardMember = ({ member }) => {
  return (
    <div className="member">
      <h3 className="position">{member.position}</h3>
      <h2 className="name">{member.name}</h2>
      <h4 className="attribute">{member.attribute}</h4>
    </div>
  );
};

export default BoardMember;
